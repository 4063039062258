import React, { useContext } from "react"
import { graphql } from "gatsby"
import BaseLayout, { getNavLinks } from "../components/base"
import {
  Box,
  BodyCopyCapitalized,
  Paragon,
  Pica,
  Brevier,
  PageLink,
} from "../utils/styledComponents"

import melon from "../../static/melon.svg"
import SEO from "../components/seo"
// import styled from "@emotion/styled"
import theme from "../utils/theme"
import { ThemeProvider } from "emotion-theming"
import NavContext from "../utils/navContext"

const AboutPage = props => {
  const navConsumer = useContext(NavContext)
  const navLinks = getNavLinks(
    props.data.allSanityCity.edges.map(city => city.node.slugMeta) || [],
    props.data.allSanityPage.edges.map(page => page.node.slugMeta) || []
  )

  const slugMeta = props.data.sanityPage.slugMeta

  React.useEffect(() => {
    navConsumer.setCurrentPage(slugMeta.seoName)
    navConsumer.setNavLinks(navLinks)
    // eslint-disable-next-line
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <BaseLayout backgroundColor="lightGreen">
        <Box position="absolute" right="0" zIndex="2">
          <img src={melon} alt="melon" />
        </Box>
        <SEO title={slugMeta.seoName} />
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            pb={[5, 6]}
            px={[4, 5]}
            minHeight="90vh"
            zIndex="99"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="space-between"
              justifyContent="center"
              minHeight={[3, 4]}
              py={[4]}
            >
              <Paragon color="blue" fontSize={[5, 7]} textAlign="center">
                Just Some <br /> Good Recs
              </Paragon>
              <Pica textAlign="center" fontSize={[4, 5]}>
                no BS, we promise
              </Pica>
            </Box>
            <Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="space-between"
                width="100%"
                height="100%"
                py={[4, 5]}
              >
                <BodyCopyCapitalized
                  maxWidth={[5]}
                  fontSize={[2, 3]}
                  lineHeight={["24px", "32px"]}
                  textAlign="justify"
                >
                  {props.data.sanityPage.content}
                </BodyCopyCapitalized>
                <PageLink to="/">
                  <Brevier cursor="pointer" textAlign="right" pt={[6, 5]}>
                    Back to Nibbling >
                  </Brevier>
                </PageLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </BaseLayout>
    </ThemeProvider>
  )
}

export const pageQuery = graphql`
  query aboutPageQuery {
    allSanityPage(
      filter: { slugMeta: { hidden: { ne: true }, seoName: {}, slug: {} } }
    ) {
      edges {
        node {
          slugMeta {
            slug
            seoName
          }
        }
      }
    }
    allSanityCity(filter: { slugMeta: { hidden: { ne: true } } }) {
      edges {
        node {
          name
          slugMeta {
            slug
            seoName
          }
        }
      }
    }
    allSanitySiteSettings {
      edges {
        node {
          title
        }
      }
    }
    sanityPage(slugMeta: { slug: { eq: "about" } }) {
      content
      name
      slugMeta {
        seoName
      }
    }
  }
`

export default AboutPage
